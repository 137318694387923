/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}
/* Global reset */
body, html {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Arial, sans-serif;
}

/* Layout for the entire dashboard */
.dashboard-layout {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: #f4f6f9; /* Matches the main background color */
}

/* Header styling */
.dashboard-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #ffffff; /* Light header to match content */
    color: #333;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); /* Soft shadow */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
}

    .dashboard-header .logo h1 {
        margin: 0;
        font-size: 1.8rem;
        font-weight: bold;
        color: #333;
    }

    .dashboard-header .navigation ul {
        list-style: none;
        display: flex;
        gap: 20px;
        margin: 0;
        padding: 0;
    }

        .dashboard-header .navigation ul li a {
            color: #007bff; /* Link color matching your design */
            text-decoration: none;
            font-size: 1rem;
        }

            .dashboard-header .navigation ul li a:hover {
                text-decoration: underline;
            }

/* Main content area */
.dashboard-main {
    flex-grow: 1; /* Ensures the content area takes up available space */
    padding: 20px;
    background-color: #f0f4f8;
}

/* Footer styling */
.dashboard-footer {
    padding: 15px 20px;
    background-color: #ffffff;
    color: #666;
    text-align: center;
    font-size: 0.9rem;
    border-top: 2px solid #e0e0e0;
    position: relative;
    width: 100%;
    /* Ensure the footer sticks to the bottom */
    margin-top: auto; /* Pushes footer to the bottom if content is small */
}

    .dashboard-footer p {
        margin: 5px 0;
    }

    .dashboard-footer a {
        color: #007bff; /* Matches the link color used in header and content */
        text-decoration: none;
    }

        .dashboard-footer a:hover {
            text-decoration: underline;
        }

.dashboard-layout {
    display: flex;
    flex-direction: column;
    min-height: 100vh; /* Ensures full viewport height */
    background-color: #f4f6f9;
}