/* LicenseInfo Container */
.license-info-container {
    margin: 0 auto;
    padding: 20px;
}

h2 {
    font-size: 1rem;
}

h4 {
    font-size: 2rem;
    color: #333;
    margin-bottom: 30px;
    text-align: center;
}

/* License cards grid layout */
.license-cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
    gap: 20px;
    margin-top: 20px;
}

/* Base style for all license cards */
.license-card {
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
    background-color: #fff;
    border-left: 5px solid transparent;
}

/* License card header with icon */
.license-card-header {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.status-icon {
    font-size: 1.8rem;
    margin-right: 10px;
}

.critical-icon {
    color: #dc3545;
}

.warning-icon {
    color: #ffc107;
}

.normal-icon {
    color: #28a745;
}

/* Hover effect */
.license-card:hover {
    transform: translateY(-5px);
}

/* Critical licenses with expired status */
.critical {
    background-color: #fce4e4;
    border-left: 5px solid #dc3545;
    color: #a23b3b;
}

/* Licenses that are expiring soon */
.expiring-soon {
    background-color: #fff3cd;
    border-left: 5px solid #ffc107;
    color: #856404;
}

/* Normal licenses */
.normal {
    background-color: #e0f7e7;
    border-left: 5px solid #28a745;
    color: #2c7c43;
}

/* General text styles */
.license-card-content p {
    font-size: 1rem;
    margin: 5px 0;
    color: #555;
}

    .license-card-content p strong {
        color: #333;
    }

/* Highlight for licenses with expiring days */
.remaining-days.highlight {
    color: #dc3545;
    font-weight: bold;
}

/* Responsive typography adjustments */
@media (max-width: 600px) {
    .license-card h2 {
        font-size: 1rem;
    }

    .license-card p {
        font-size: 0.9rem;
    }
}

/* Spinner container */
.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.icon-container {
    display: flex;
    justify-content: center;
    margin-bottom: 10px; /* Spacing between icon and title */
}
