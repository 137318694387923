.alerts-dashboard {
    display: flex;
    justify-content: space-around;
    gap: 20px;
    margin: 40px 20px;
    flex-wrap: wrap;
}

/* Modern card design with shadow and gradient */
.alert-box {
    text-align: center;
    padding: 20px;
    background: white;
    border-radius: 15px;
    width: 250px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    cursor: pointer;
}

    /* Hover effect with subtle scaling and shadow */
    .alert-box:hover {
        transform: translateY(-5px);
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
    }

/* Modern icon styling */
.alert-icon {
    font-size: 2.5rem;
    color: white;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 15px;
    transition: background 0.3s ease;
}

/* Large number for alert counts */
.alert-count {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 10px;
    color: #333;
}

/* Label styling with consistent font and color */
.alert-label {
    font-size: 1.2rem;
    color: #888;
    letter-spacing: 0.5px;
    font-family: 'Roboto', sans-serif;
}

/* Example gradients for alert types (can pass from alertData) */
.alert-icon[data-high] {
    background: linear-gradient(45deg, #ff4b2b, #ff416c); /* High Alerts */
}

.alert-icon[data-medium] {
    background: linear-gradient(45deg, #ffba00, #fcb045); /* Medium Alerts */
}

.alert-icon[data-low] {
    background: linear-gradient(45deg, #32a852, #00bfa5); /* Low Alerts */
}

.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.popup-content {
    position: relative; /* Keep the relative positioning */
    padding: 20px; /* Add some padding */
    background: #fff; /* Set background color */
    border-radius: 8px; /* Round the corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add shadow for depth */
    max-height: 80vh;
    width: 90%; /* Set a fixed width */
    max-width: 90%; /* Allow responsive resizing but not exceed 90% of viewport width */
    display: flex; /* Use flexbox for layout */
    flex-direction: column; /* Stack children vertically */
    margin: 20px;
}

.popup-header {
    display: flex; /* Use flexbox for layout */
    justify-content: left; /* Space out the header and close button */
    align-items: center; /* Center items vertically */
    padding-bottom: 10px; /* Space between header and content */
    border-bottom: 1px solid #ccc; /* Optional: add a bottom border */
}

.alerts-container {
    flex: 1; /* Allow the alerts container to grow */
    overflow-y: auto; /* Enable vertical scrolling for the alerts container */
    padding: 10px 0; /* Optional: Add padding */
    max-height: calc(80vh - 60px); /* Adjust height based on header size */
}

.alerts-table {
    width: 100%; /* Ensure the table takes the full width */
    border-collapse: collapse; /* Optional: clean table borders */
}

    .alerts-table th,
    .alerts-table td {
        padding: 10px; /* Add padding to table cells */
        text-align: left; /* Align text to the left */
        border-bottom: 1px solid #ccc; /* Optional: add bottom border to cells */
    }

.popup-close {
    background-color: transparent; /* Make the background transparent */
    border: none; /* Remove border */
    cursor: pointer; /* Change cursor to pointer */
    font-size: 1.5rem; /* Increase font size */
    color: #ff5c5c; /* Change color to a noticeable one */
    transition: color 0.3s ease; /* Smooth color transition */
    position: absolute; /* Position the button in the top-right corner */
    top: 10px; /* Adjust positioning */
    right: 10px; /* Adjust positioning */
}

    .popup-close:hover {
        color: #ff0000; /* Change color on hover for better feedback */
    }


.header-info {
    display: flex; /* Use flexbox for layout */
    align-items: center; /* Center items vertically */
}

.alert-icon-popup {
    width: 40px; /* Adjust size as needed */
    height: 40px; /* Adjust size as needed */
    border-radius: 50%; /* Make it circular */
    display: flex; /* Center icon */
    justify-content: center; /* Center icon */
    align-items: center; /* Center icon */
    margin-right: 10px; /* Space between icon and text */
    font-size: 1.5rem;
    color: white;
    transition: background 0.3s ease;
}

    .alert-icon-popup[data-high] {
        background: linear-gradient(45deg, #ff4b2b, #ff416c); /* High Alerts */
    }

    .alert-icon-popup[data-medium] {
        background: linear-gradient(45deg, #ffba00, #fcb045); /* Medium Alerts */
    }

    .alert-icon-popup[data-low] {
        background: linear-gradient(45deg, #32a852, #00bfa5); /* Low Alerts */
    }


.popup-header h3 {
    margin:0 !important;
}

.pagination {
    display: flex;
    justify-content: center; /* Center the pagination controls */
    margin-top: 10px; /* Add some space above the controls */
}

    .pagination button {
        margin: 0 5px; /* Space between buttons */
        padding: 5px 10px; /* Button padding */
        border: none; /* Remove border */
        background-color: #007bff; /* Bootstrap primary color */
        color: white; /* Text color */
        cursor: pointer; /* Pointer cursor on hover */
        border-radius: 4px; /* Rounded corners */
        transition: background-color 0.3s; /* Smooth background color transition */
    }

        .pagination button:disabled {
            background-color: #ccc; /* Gray out disabled buttons */
            cursor: not-allowed; /* Not-allowed cursor */
        }

    .pagination span {
        align-self: center; /* Center the page text */
    }
