.alerts-container {
    margin: 20px;
}

h3 {
    font-family: Arial, sans-serif;
    margin-bottom: 10px;
}

.alerts-table {
    width: 100%;
    border-collapse: collapse;
}

    .alerts-table th, .alerts-table td {
        padding: 10px;
        text-align: left;
        font-family: Arial, sans-serif;
    }

    .alerts-table th {
        background-color: #f2f2f2;
        border-bottom: 1px solid #ccc;
    }

    .alerts-table td {
        border-bottom: 1px solid #ccc;
    }

.alert-error {
    background-color: #ffe6e6;
}

.alert-info {
    background-color: #e6f7ff;
}

.alert-warning {
    background-color: #fffbe6;
}

.alert-icon {
    font-size: 18px;
    margin-right: 5px;
}
