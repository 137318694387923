/* General Styles */
.endpoint-list {
    margin: 0 auto;
    padding: 20px;
}

h1 {
    text-align: center;
    color: #333;
}

h2 {
    margin-bottom: 10px;
    color: #0056b3;
}

h4 {
    text-align: center;
    margin-top: 10px;
    color: #333;
}

p {
    margin: 0;
}

/* Dashboard Container */
.dashboard-container {
    padding: 20px;
    background-color: #f0f4f8; /* Light gray background for dashboard */
}

/* Responsive Grid for Services and Charts */
.dashboard-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* Responsive grid layout */
    gap: 20px; /* Space between boxes */
    margin-top: 20px;
}

/* Service Box Styles */
.service-box {
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Light shadow for depth */
    transition: transform 0.3s ease;
    cursor: pointer;
    background-color: #fff;
    border-left: 5px solid transparent; /* Default transparent left border */
    display: flex;
    flex-direction: column; /* Flexbox for better content alignment */
    justify-content: space-between;
}

    .service-box:hover {
        transform: translateY(-5px); /* Slight lift on hover */
    }

/* Healthy Service Status */
.healthy {
    background-color: #e0f7e7; /* Light green for healthy */
    border-left-color: #28a745; /* Dark green left border */
    color: #2c7c43;
}

/* Problematic Service Status */
.problematic {
    background-color: #fce4e4; /* Light red for problematic */
    border-left-color: #dc3545; /* Dark red left border */
    color: #a23b3b;
}

/* Service Box Headings */
.service-box h2 {
    font-size: 16px;
    margin-bottom: 10px;
    color: #0056b3; /* Darker blue for headings */
}

/* Service List Styling */
.service-box ul {
    padding-left: 20px;
    list-style-type: disc; /* Display as disc list */
}

    .service-box ul li {
        background-color: #fff;
        padding: 10px;
        margin-bottom: 5px;
        border-radius: 4px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); /* Shadow for each list item */
        font-size: 14px;
    }

/* General Text Styles */
.service-box p {
    font-size: 14px;
    color: #555; /* Soft gray for text */
}

/* Error/Problematic Service Message */
.service-box .error {
    font-size: 12px;
    color: #dc3545;
    margin-top: 5px;
}

/* Widget Styles */
.widget {
    margin: 20px 0; /* Margin to separate widgets */
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
}

/* Chart Widget Styling */
.chart-widget {
    width: 100%; /* Use full width of the grid cell */
    height: 250px; /* Adjust height for better dashboard fit */
    display: inline-block;
}

/* Responsive settings for the charts */
.widget canvas {
    max-width: 100%;
    max-height: 200px; /* Smaller canvas size */
}

/* Problematic Service Message */
.error {
    color: #721c24;
    font-weight: bold;
}

/* Responsive Grid for Services and Charts */
.dashboard-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* Responsive grid layout */
    gap: 20px; /* Space between boxes */
    margin-top: 20px;
}

/* Service Grid for compact boxes */
.service-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* Responsive grid layout for services */
    gap: 20px; /* Space between service boxes */
}
.amber {
    color: orange; /* Amber color for minor issues */
    font-weight: bold;
}

.red {
    color: red; /* Red color for critical issues */
    font-weight: bold;
}

/* Spinner container for centering the spinner */
.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px; /* Make sure it's at least the viewport height */
}

/* Simple CSS spinner */
.spinner {
    border: 8px solid #f3f3f3; /* Light grey */
    border-top: 8px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 1s linear infinite;
}

/* Keyframes for the spinner animation */
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.widget-large {
    grid-column: span 2; /* Make the widget span two columns */
}

.widget-xlarge {
    grid-column: span 3; /* Make the widget span two columns */
}

.icon-container {
    display: flex;
    justify-content: center;
    margin-bottom: 10px; /* Spacing between icon and title */
}